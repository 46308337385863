const mediaSourceSize = { width: 2384, height: 1826 };

export const presentationFeatures = [
  {
    header: 'home.featuresPresentation.features.first.header',
    description: 'home.featuresPresentation.features.first.description',
    mediaSource: {
      path: '/img/smComputingScreens/computing-browse.webp',
      description: 'home.featuresPresentation.features.first.alt',
      size: mediaSourceSize,
    },
  },
  {
    header: 'home.featuresPresentation.features.second.header',
    description: 'home.featuresPresentation.features.second.description',
    mediaSource: {
      path: '/img/smComputingScreens/computing-choose.webp',
      description: 'home.featuresPresentation.features.second.alt',
      size: mediaSourceSize,
    },
    isGlowingSquareVisible: true,
    glowingSquarePosition: {
      positionHorizontal: 'right',
      positionVertical: 'top',
    },
  },
  {
    header: 'home.featuresPresentation.features.third.header',
    description: 'home.featuresPresentation.features.third.description',
    mediaSource: {
      path: '/img/smComputingScreens/computing-monitor.webp',
      description: 'home.featuresPresentation.features.third.alt',
      size: mediaSourceSize,
    },
    isGlowingSquareVisible: false,
  },
  {
    header: 'home.featuresPresentation.features.fourth.header',
    description: 'home.featuresPresentation.features.fourth.description',
    mediaSource: {
      path: '/img/smComputingScreens/computing-manage.webp',
      description: 'home.featuresPresentation.features.fourth.alt',
      size: mediaSourceSize,
    },
    isGlowingSquareVisible: false,
  },
  {
    header: 'home.featuresPresentation.features.fifth.header',
    description: 'home.featuresPresentation.features.fifth.description',
    mediaSource: {
      path: '/img/smComputingScreens/computing-jupyter.webp',
      description: 'home.featuresPresentation.features.fifth.alt',
      size: mediaSourceSize,
    },

    isGlowingSquareVisible: true,
    glowingSquarePosition: {
      positionHorizontal: 'left',
      positionVertical: 'top',
    },
  },
];
