import dynamic from 'next/dynamic';
import HomeMainSection from 'components/Layout/Home/HomeMainSection';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import HomePricing from 'components/Layout/Home/HomePricing';
import HomeVideo from 'components/Layout/Home/HomeVideo';
import { NextPageContext } from 'next';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import LoadingIndicator from 'components/LoadingIndicator';
import { getHost } from 'services/EnvProvider';

const HomeFeaturesPresentation = dynamic(
  () => import('components/Layout/Home/HomeFeaturesPresentation'),
  { loading: () => <LoadingIndicator /> },
);

const StartComputing = dynamic(
  () => import('components/Layout/Home/StartComputing'),
  { loading: () => <LoadingIndicator /> },
);

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{t('home.meta.title') || 'SimplePod.ai'}</title>
        <meta
          name="keywords"
          content={t('home.meta.keywords') || 'rent gpu, simplepod'}
        />
        <meta
          name="description"
          content={t('home.meta.description') || 'SimplePod.ai - rent gpu easy'}
        />
        <link rel="canonical" href={`https://${getHost()}/`} />
      </Head>
      <HomeMainSection />
      <HomeVideo />
      <HomePricing />
      <HomeFeaturesPresentation />
      <StartComputing />
    </>
  );
};

export async function getStaticProps(context: NextPageContext) {
  const { locale = 'en' } = context;

  return {
    props: await serverSideTranslations(locale),
  };
}

export default Home;
