import Video from 'components/Video';
import SectionTitle from 'components/SectionTitle';

const HomeVideo = () => {
  return (
    <div className="home-video">
      <SectionTitle
        header="home.howToRent.header"
        description="home.howToRent.description"
      />
      <Video videoId="Y3iroGDIeZM" />
    </div>
  );
};

export default HomeVideo;
