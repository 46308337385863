interface Props {
  videoId: string;
  setPlayerLoaded: Function;
}

const VideoPlaceholder = (props: Props) => {
  const { videoId, setPlayerLoaded } = props;

  return (
    <div
      className="video-placeholder"
      onClick={() => {
        setPlayerLoaded(true);
      }}
    >
      <div
        className="video-placeholder__thumbnail"
        style={{
          backgroundImage: `url(https://i.ytimg.com/vi_webp/${videoId}/sddefault.webp)`,
        }}
      />
      <div className="video-placeholder__play-button">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
          <path d="M8 5v14l11-7z" />
        </svg>
      </div>
    </div>
  );
};

export default VideoPlaceholder;
