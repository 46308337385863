import { Trans, useTranslation } from 'next-i18next';
import { ButtonRegisterOrLogin } from 'components/Layout/Home/ButtonRegisterOrLogin';

const HomeHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="content-header">
      <h1 className="hero-header">
        <Trans i18nKey="home.main.header">
          Rent GPUs, build and train
          <span className="blue-text-marker">AI/ML models</span>
        </Trans>
      </h1>
      <p className="hero-description">{t('home.main.description')}</p>
      <ButtonRegisterOrLogin page="home" />
    </div>
  );
};

export default HomeHeader;
