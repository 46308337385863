import { useEffect, useRef } from 'react';

interface Props {
  videoId: string;
  onReady?: () => void;
}

const loadYouTubeScript = () => {
  if (!document.getElementById('youtube-iframe-api')) {
    const script = document.createElement('script');

    script.src = 'https://www.youtube.com/iframe_api';
    script.id = 'youtube-iframe-api';
    document.body.appendChild(script);
  }
};

export const useYouTubeIframe = (props: Props) => {
  const { videoId, onReady } = props;
  const playerRef = useRef<HTMLDivElement>(null);
  const youtubePlayer = useRef<any>(null);

  useEffect(() => {
    const initializePlayer = () => {
      if (playerRef.current) {
        youtubePlayer.current = new (window as any).YT.Player(
          playerRef.current,
          {
            height: '360',
            width: '640',
            videoId,
            events: {
              onReady,
            },
          },
        );
      }
    };

    const checkAndInitialize = () => {
      if ((window as any).YT && (window as any).YT.Player) {
        initializePlayer();
      } else {
        (window as any).onYouTubeIframeAPIReady = initializePlayer;
      }
    };

    loadYouTubeScript();
    checkAndInitialize();

    return () => {
      youtubePlayer.current?.destroy();
    };
  }, [videoId, onReady]);

  return playerRef;
};
