import { useYouTubeIframe } from 'hooks/useYoutubeIframe';
import { useState } from 'react';
import VideoPlaceholder from './VideoPlaceholder';

interface Props {
  videoId: string;
}

const VideoPlayer = (props: Props) => {
  const { videoId } = props;

  const [playerLoaded, setPlayerLoaded] = useState(false);
  const playerRef = useYouTubeIframe({
    videoId,
    onReady: () => setPlayerLoaded(true),
  });

  return (
    <div className="video-wrapper">
      {!playerLoaded && (
        <VideoPlaceholder videoId={videoId} setPlayerLoaded={setPlayerLoaded} />
      )}
      <div ref={playerRef} className="video" />
    </div>
  );
};

export default VideoPlayer;
