const MainImagesLoader = () => {
  return (
    <>
      <div className="content-images">
        <div className="main-images__connection image-loader">
          <div className="image-loader--content" />
        </div>
        <div className="main-images__system image-loader">
          <div className="image-loader--content" />
        </div>
      </div>
      <div className="content-images-slider slider-loader">
        <div className="main-images__connection image-loader">
          <div className="image-loader--content" />
        </div>
      </div>
    </>
  );
};

export default MainImagesLoader;
