interface Props {
  height?: number;
  size?: 'small';
}

const LoadingIndicator = (props: Props) => {
  const height = props.height || 100;
  const isSmall = props.size === 'small';
  const dotRatio = isSmall ? 12 : 4;

  const loaderStyle = {
    height: `${height}px`,
  };

  const elementStyle = {
    height: `${height / dotRatio}px`,
    width: `${height / dotRatio}px`,
    minHeight: isSmall ? '8px' : '16px',
    minWidth: isSmall ? '8px' : '16px',
    margin: isSmall ? '2px' : '2px 6px',
  };

  return (
    <div className="loader__wrapper">
      <div style={loaderStyle} className="loader">
        <div style={elementStyle} className="loader__dot"></div>
        <div style={elementStyle} className="loader__dot"></div>
        <div style={elementStyle} className="loader__dot"></div>
      </div>
    </div>
  );
};

export default LoadingIndicator;
