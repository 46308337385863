import PageHeroBackground from 'components/Layout/PageHeroBackground';
import HomeHeader from './HomeContent/HomeHeader';
import HomeHeroNavigation from './HomeHeroNavigation';
import InstanceBrands from './InstanceBrands';
import GlowingSquare from 'components/Layout/GlowingSquare';
import dynamic from 'next/dynamic';
import MainImagesLoader from './HomeContent/HomeMainImages/MainImagesLoader';

const Images = dynamic(
  () =>
    import('components/Layout/Home/HomeMainSection/HomeContent/HomeMainImages'),
  {
    ssr: false,
    loading: () => <MainImagesLoader />,
  },
);

const HomeMainSection = () => {
  return (
    <div className="main-section-wrapper">
      <GlowingSquare
        squareSideLengthRelativeToScreen={50}
        positionVertical="bottom"
        positionHorizontal="center"
      />
      <PageHeroBackground>
        <div className="main-section">
          <HomeHeroNavigation />
          <div className="main-section__body">
            <div className="main-section__body-content">
              <HomeHeader />
              <Images />
            </div>
            <InstanceBrands />
          </div>
        </div>
      </PageHeroBackground>
    </div>
  );
};

export default HomeMainSection;
